<template>
  <div>
    <div class="breadcrumb">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item
          ><i class="el-icon-s-home"></i> 首页</el-breadcrumb-item
        >
        <el-breadcrumb-item>提现管理</el-breadcrumb-item>
      </el-breadcrumb>
      <el-button type="success" style="height: 36px" @click="reload">
        <i style="font-size: 18px" class="el-icon-refresh-right"></i>
      </el-button>
    </div>

    <div
      style="
        padding: 10px 20px;
        border-bottom: 1px solid #e5e5e5;
        font-size: 14px;
        white-space: nowrap;
        display: flex;
        align-items: center;
      "
    >
      <el-col :span="6">
        <span>创建日期：</span>
        <el-date-picker
          v-model="creatTime"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="15">
        <el-button type="success" size="mini" style="margin-left: 5px">
          <div style="display: flex; align-items: center; font-size: 14px">
            <img
              style="width: 16px; height: 16px"
              :src="require('@/assets/iconImg/logo.png')"
            />
            <span style="margin-left: 3px">查询</span>
          </div>
        </el-button>
        <el-button type="primary" size="mini" style="margin-left: 5px">
          <div style="display: flex; align-items: center; font-size: 14px">
            <img
              style="width: 16px; height: 16px"
              :src="require('@/assets/iconImg/logo.png')"
            />
            <span style="margin-left: 3px">打印</span>
          </div>
        </el-button>
        <el-button type="primary" size="mini" style="margin-left: 5px">
          <div style="display: flex; align-items: center; font-size: 14px">
            <img
              style="width: 16px; height: 16px"
              :src="require('@/assets/iconImg/logo.png')"
            />
            <span style="margin-left: 3px">导出报表</span>
          </div>
        </el-button>
      </el-col>
    </div>

    <div style="padding: 20px">
      <p style="font-size: 35px; text-align: center">广州水便利营业日报</p>
      <el-table :data="tableData" border style="width: 100%;margin-top: 20px;">
        <el-table-column label="摘要">
          <template slot-scope="scope">
            {{ scope.row.title1 }}
          </template>
        </el-table-column>
        <el-table-column label="数量">
          <template slot-scope="scope"> {{ scope.row.number1 }}单 </template>
        </el-table-column>
        <el-table-column label="金额">
          <template slot-scope="scope"> {{ scope.row.money1 }}元 </template>
        </el-table-column>
        <el-table-column label="摘要">
          <template slot-scope="scope">
            <span>{{ scope.row.title2 }}</span>
            <span
              v-if="scope.row.id == 1 || scope.row.id == 5 || scope.row.id == 8"
              ><el-link type="primary">（点击查看）</el-link></span
            >
          </template>
        </el-table-column>
        <el-table-column label="数量">
          <template slot-scope="scope">
            {{ scope.row.number2 }}
            <span v-if="scope.row.unit">个</span>
            <span v-else>张</span>
          </template>
        </el-table-column>
        <el-table-column label="金额">
          <template slot-scope="scope"> {{ scope.row.money2 }}元 </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      creatTime: "",

      tableData: [],
    };
  },
  mounted() {},
  computed: {},
  methods: {
    reload() {
      location.reload();
    },
  },
};
</script>
<style lang="less" scoped>
.breadcrumb {
  height: 40px;
  line-height: 40px;
  box-sizing: content-box;
  border-bottom: 1px solid #e5e5e5;
  padding: 0 20px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}
/deep/ .el-col {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  font-size: 14px;
  margin: 0 5px;
}
/deep/ .cell {
  padding: 0 !important;
  white-space: nowrap !important;
}
</style>
