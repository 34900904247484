import { render, staticRenderFns } from "./salesReport.vue?vue&type=template&id=182b06d7&scoped=true&"
import script from "./salesReport.vue?vue&type=script&lang=js&"
export * from "./salesReport.vue?vue&type=script&lang=js&"
import style0 from "./salesReport.vue?vue&type=style&index=0&id=182b06d7&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "182b06d7",
  null
  
)

export default component.exports